.title {
  padding-left: 30px;
  padding-top: 20px;
}

.content {
  padding-bottom: 10px;
}

.right {
  float: right;
}
