.activationHash {
  margin: 50px;
  text-align: center;
}

.title {
  padding-top: 20px;
}

.content {
  margin: 10px;
  padding: 20px;
}

.logo {
  margin-left: 0;
  margin-right: 0;
}

.loader {
  margin-top: 20px;
}
