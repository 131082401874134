.link {
  margin-left: 20px;
  margin-right: 20px;
  text-decoration: none;
  cursor: pointer;
}

.white {
  color: white;
}

.profeature {
  color: lightgray;
  cursor: not-allowed;
}

.black {
  /*   color: black;
 */
}

.flexGrow {
  flex-grow: 1;
}

/* .theme {
  top: -10px;
} */
