.login {
  margin: 0px;
  text-align: left;
}

.title {
  padding-left: 30px;
  padding-top: 20px;
}

.content {
  margin: 10px;
  padding: 20px;
  padding-top: 0;
}

.logo {
  margin-left: 0;
  margin-right: 0;
}

.space {
  margin-right: 5px;
}

.btn_pw_forgotten {
  margin: 10px !important;
}
