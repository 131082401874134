.profile {
  margin: 20px;
}

.button {
  margin-right: 20px !important;
}

.link {
  color: inherit;
  text-decoration: none;
  cursor: inherit;
}

.card {
  min-height: 42vh;
}
